//屏幕自适应
// function show() {
//     var n;
//     var w = window.document.documentElement.clientWidth;
//     var h = window.document.documentElement.clientHeight-5;

//     if (w < 950) { w = 950; }
//     if (h < 300) { h = 300; }
//     var x = w / 1920;
//     var y = h / 1080;
//     var m;
//     if (x <= y) {
//         m = x;
//     } else {
//         m = y;
//     }
//     n = 16 * m;
//     document.getElementsByTagName('html')[0].style.fontSize = n + 'px';
// }
// show();
// var resizeWorldMapContainer = function () {
//     var w = document.documentElement.clientWidth;
//     var h = document.documentElement.clientHeight;
// }

// //用于使chart自适应高度和宽度
// window.onresize = function () {
//     show();
//     //重置容器高宽
//     resizeWorldMapContainer();
// }
// window.onload = function () {
//     show();
//     resizeWorldMapContainer();
// }
// resizeWorldMapContainer();
//方法2好用
// window.addEventListener('resize',function(){
//     reset_font();
// })
// function reset_font(){
//     //console.log(111);
//     // 获取当前页面的根元素
//     let doc=document.documentElement;
//     // 获取当前设备的视口宽度
//     let view_width=doc.clientWidth;
//     // 计算 font-size 的值
//     let fontSize=view_width /15;
//     // 设置当前的html的font-size 属性的值
//     doc.style.fontSize = fontSize+'px';
// }
//方法3
// cwidth = 1440.0;  //页面默认宽度
//             //JS监听浏览器文字大小代码
// 	(function (doc, win) {
//                 var docEl = doc.documentElement,
//                     resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
//                     recalc = function () {
//                         var clientWidth = docEl.clientWidth;
//                         if (!clientWidth) return;
//                         rate = (clientWidth / cwidth) ;
// 			cwidth = clientWidth*1.0;
// 			changeFont(rate);
//                     };

//                 if (!doc.addEventListener) return;
//                 win.addEventListener(resizeEvt, recalc, false);
//                 doc.addEventListener('DOMContentLoaded', recalc, false);
//             })(document, window);

// 	function changeFont(rate)
// 	{
// 		var child_arr = document.querySelectorAll('body *');
// 		for(var i=0;i<child_arr.length;i++)
// 		{
// 			if(child_arr[i].style.fontSize != "")
// 			{
// 				font=child_arr[i].style.fontSize.replace("px","");
// 				child_arr[i].style.fontSize = font*rate;
// 			}
// 		}

// 	}
