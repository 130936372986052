const $ = require("jquery");

let nav01 = `
<div style="height:80px">
<div class="container-fluid navBox">
<div class="row">
    <div class="nav">


    <div class="col-md-2 col-xs-2 weetgoLogo">
    <div class="logo">
    <a href="./index.html" title='未高科技'>
        <img src="./static/image/index/fire.png" alt="未高科技">
        </a>
    </div>
</div>

        <div class="col-md-10 col-xs-10 navBar">

            <ul class="navHeader">
             <div class="language c1">EN/中</div>
                <li><a href="./index.html">首页</a></li>
                <li>
                    <a href="javascript:;">产品中心</a>
                    <i class="nav_arrows"></i>
                    <ul class="navContent" style="display: none;">
                        <li>
                            <a href="./product.html">火灾调查仿真培训</a>
                        </li>
                    </ul>
                </li>
                <li>
                <a href="javascript:;">课程中心</a>
                <i class="nav_arrows"></i>
                <ul class="navContent" style="display: none;">
                    <li>
                        <a href="./study.html">火灾调查培训课程</a>
                    </li>
                </ul>
            </li>
                <li>
                <a href="javascript:;">用户案例</a>
                <i class="nav_arrows"></i>
                <ul class="navContent" style="display: none;">
                    <li>
                        <a href="./user.html">火灾调查用户案例</a>
                    </li>
                </ul>
            </li>
              
                <li>
                    <a href="javascript:;">关于我们</a>
                    <i class="nav_arrows"></i>
                    <ul class="navContent" style="display: none;">
                        <li>
                            <a href="./companyProfile.html">企业介绍</a>
                            <a href="./enterpriseHonor.html">企业荣誉</a>
                            <a href="./contactUS.html">联系我们</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</div>
</div>
</div>

`;

let footer = `
<div class="container-fluid footerBox">

<div class="row footer">
    <div class="col-md-4 col-xs-12 contact">
        <h3>上海未高科技有限公司</h3>
        <p>电话：021-80392588</p>
        <p>邮箱：xrfire@weetgo.com</p>
        <div class="contactAdress clearfix" style="/*! float: left; */">
  <p>地址：</p>
  <p>上海市嘉定区金沙江西路1555弄20号4层</p>
</div>
    </div>
    <div class="col-md-6 col-xs-12 details">
  <div class="d-1">
 <div class="dl1">首页</div>
 <div class="dl1 href="javascript:;"">产品中心</div>
 <div class="dl1" href="javascript:;">课程中心</div>
 <div class="dl1" href="javascript:;">用户案例</div>
 <div class="dl1" href="javascript:;">关于我们</div>
  </div>
  <div class="d-2">
  <div class="dl2"></div>
  <div class="dl2"><a href="./product.html">火灾调查仿真培训</a></div>
  <div class="dl2"><a href="./study.html">火灾调查培训课程</a></div>
  <div class="dl2"><a href="./user.html">火灾调查用户案例</a></div>
  <div class="dl2"> <a href="./companyProfile.html">企业介绍</a></div>
  </div>
  <div class="d-2">
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"> <a href="./enterpriseHonor.html">企业荣誉</a></div>
  </div>
  <div class="d-2">
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"></div>
  <div class="dl2"><a href="./contactUS.html">联系我们</a></div>
  </div>     
    </div>
    <div class="col-md-2 col-xs-12 code">
        <img src="./static/image/index/xrfire.png" alt="二维码">
        <span class="erweima">扫一扫关注公众号</span>
    </div>
    <div class="beian">
    <div class="foot1"><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备17049715号-6</a> </div>
 <div class="foot1">©2022 WeetGo.All Rights Reserved</div>
 <div class="foot1">上海未高科技有限公司</div>
    </div>
</div>

</div>
`;

let backTop = `
<div id="toptap">
            <span>TOP</span>
            <i class="top_icon"></i>  
            <div class="top"></div>     
        </div>

`;

// let robot = `
// <div class="robot"></div>
// `;
let robot = `
<div class="robot">
<img src="./static/image/index/robot.png" alt="π宝">
        <div class="tag robotMore">
        
            <div class="">点我，了解更多吧！</div>
        </div>
`;
window.onscroll = function () {
  //为了保证兼容性，这里取两个值，哪个有值取哪一个
  //scrollTop就是触发滚轮事件时滚轮的高度
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //console.log("滚动距离" + scrollTop);
  if(scrollTop>=500){
    $("#toptap").addClass("sideNavigation")
    $("#toptap").removeClass("sideNavigation2")
  }
  else{
    $("#toptap").addClass("sideNavigation2")
    $("#toptap").removeClass("sideNavigation")
  
  }
}

$(function () {
  if (!$(".navBox").hasClass("navHeader")) {
    $("body").prepend(nav01);
    $("body").append(footer);

    if (!$(".navBox").hasClass("navHeader")) {
      // 获取当前页面名称
      let htmlName = $("body").attr("data-name");
      //console.log('htmlName', htmlName);
      $(".navBar a").each(function (i, el) {
        if ($(el).text() == htmlName) {
          $(this).addClass("navListStyle");
          $(this).parents("li").find("a").eq(0).addClass("navListStyle");
        }
      });

      // 导航栏点击事件
      let dom = document.querySelectorAll(".navHeader>li").length;
      

      // 导航栏悬浮事件
      for (let i = 0; i < dom; i++) {
        $(".navHeader>li")
          .eq(i)
          .on("mouseenter", function () {
            // $(this).children(".navContent").show();
            $(this).children(".navContent").toggle();
            $(this)
              .children(".nav_arrows")
              .removeClass("rotateAnimate")
              .addClass("rotateAnimateRegain");
          });
        $(".navHeader>li")
          .eq(i)
          .on("mouseleave", function () {
            // $(this).children(".navContent").hide();
            $(this).children(".navContent").toggle();
            $(this)
              .children(".nav_arrows")
              .removeClass("rotateAnimateRegain")
              .addClass("rotateAnimate");
          });
      }
    } else {
      $("body").remove(".navBox");
      $("body").remove(".footerBox");
    }

    $("body").append(backTop);
    $("body").append(robot);
  }

  backTotop();
  paibao();
  topAnimation();
});

function backTotop() {
  $(".top,.top_icon").on("click", function () {
    // $('html,body')兼容问题body属于chrome
    $("html,body").animate({
      scrollTop: 0,
    });
  });
}
function paibao() {
  $(".robot .robotMore div").on("click", function () {
    // $(".messagePop").toggle();
    $(".messagePop").css("display", "block");
    // console.log("ceshi", 点击效果);
  });
  $(".robot").on("click", function () {
    // $(".messagePop").toggle();
    $(".messagePop").css("display", "block");
    // console.log("ceshi", 点击效果);
  });
  $(".pop-close").on("click", function () {
    $(".messagePop").slideUp();
  });
}


$(".c1").on("click", function () {

$('.language').css({"color":"white","transform":"scale(1.5)"})
})
function topAnimation() {
  $(".top").on({
    mouseenter: function () {
      //鼠标移入回调
      // $(".top_icon").removeClass("top_icon").addClass("top_icon02");
      $(".top_icon").css(
        "background",
        "url(./static/image/index/video_nav_arrows01.gif)"
      );
    },
    mouseleave: function () {
      //鼠标移出回调
      // $(".top_icon").removeClass("top_icon02").addClass("top_icon");
      $(".top_icon").css(
        "background",
        "url(./static/image/index/video_nav_arrows01.png)"
      );
    },
  });
}
