// const $ = require("jquery");

// 弹窗
let messagePop = `
<div class="messagePop" style="display: none;">
        <div class="content">
            <div class="contactLife">
            <div class="weixin"></div>
                <h3 class="title_Style01">汲经理</h3>
                <div class="contactInfo">
                    <ul>
                        <li>电话：18616028963</li>
                        <li>&nbsp &nbsp &nbsp &nbsp  &nbsp &nbsp021-80392588</li>
                        <li>邮箱：jixb@weetgo.com</li>
                    </ul>
                </div>
                
            </div>
            <i class="close pop-close "></i>

        </div>
    </div>
`;

$(function () {
  $("body").append(messagePop);

  // 给点击查看更多添加click事件
  $(".buttonStyle,.robot").on("click", function () {
    // $(".messagePop").toggle();
    $(".messagePop").css("display", "block");
  });
  $(".pop-close,.close").on("click", function () {

    $(".messagePop").slideUp();
  });
});

